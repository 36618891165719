<template>
  <section class="privacy-policy bs-py-4">
    <div class="bs-container">
      <v-card class="bs-shadow-none">
        <div class="pa-6 pa-md-12">
          <PrivacyPolicy />
        </div>
      </v-card>
    </div>
  </section>
</template>

<script>
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

export default {
  components: {
    PrivacyPolicy,
  },
};
</script>

<style scoped>
.privacy-policy p:last-of-type {
  margin-bottom: 0 !important;
}
.privacy-policy ul {
  margin: 1rem 0 !important;
}
</style>
